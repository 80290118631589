import React, { useState, useEffect } from "react";
import { generateVerificationCode } from "./hashAnswers";
import AnswerChecker from "../../AnswerChecker";

const DOOR_ANSWERS = [
  "f6e577e2340ed2966db8a2a31fe882c7b01319193fe65abe3a069ed694223a65",
  "5420327952ce2ad09e495cb126b221e32c2a8e3080829c144cadc7c018fae063",
  "9fbc6c452a82f835f708a4b3ceb389d9bb8751633321d770578b2b29313184f1",
  "f80827e75ffc711bdfce15a64c37deb5f70f17be13ae0d313847fb311124bd7e",
  "b2ceae25f04ca611db39404526b98e61db4fd22f5a6eaf279cc8683190f4a7d5",
  "044d2caff2503ae7a062bae959d9e3e95785c7908863a4536ed02aa53d7bfceb",
  "9d62613181cd7486e4cea6683068231504a476e4d65644a51e6d3f421746198b",
  "22edef3df83b1170b95a74b2295b87fe4fac42f9ed68d11f9b236ea6dadcf3cf",
  "36cac78ba41fec7b04b57bca07afd5bb149e562bd256042dd353d33e37aec742",
  "466893d19b11c4e90d7f0b7e83c8bf8f88c22c93b23c480962c63c8bbaba84b0",
  "1d50e0f9fb2ebab8f3c10c296100c7e409fe147ddd96ea8c08ecb4d21f570d49",
  "147ff92b078eb388cc40c0b18aec66817d55a75ff0f8f63cb01968771b998575",
];

const DOOR_IMAGES = [
  "1 red.jpeg",
  "2 orange.jpeg",
  "3 yellow.jpeg",
  "4 green.jpeg",
  "5 blue.jpeg",
  "6 indigo.jpeg",
  "7 violet.jpeg",
  "8 Cyan.jpeg",
  "9 zodiac.jpeg",
  "10 brown.jpeg",
  "11 flags 2.jpeg",
  "12 black.jpeg",
];

export function DoorGame() {
  const [currentDoor, setCurrentDoor] = useState(0);
  const [userAnswers, setUserAnswers] = useState<string[]>([]);
  const [verificationCode, setVerificationCode] = useState<string>("");

  const handleNextDoor = (answer: string) => {
    setUserAnswers(prev => [...prev, answer]);
    setCurrentDoor(prev => prev + 1);
  };

  useEffect(() => {
    // When all doors are completed, generate verification code
    if (currentDoor >= DOOR_ANSWERS.length && userAnswers.length === DOOR_ANSWERS.length) {
      generateVerificationCode(userAnswers).then(code => setVerificationCode(code));
    }
  }, [currentDoor, userAnswers]);

  if (currentDoor >= DOOR_ANSWERS.length) {
    return (
      <div style={styles.container}>
        <h1>Congratulations!</h1>
        <p>You've solved all the riddles and completed the Egyptian Game!</p>
        <div style={styles.verificationContainer}>
          <p>Your verification code: <strong>{verificationCode}</strong></p>
          <p>Please email this code to info@escapekey.ca verify your completion.</p>
        </div>
      </div>
    );
  }

  return (
    <div style={styles.container}>
      <h2>Door {currentDoor + 1}</h2>
      <div style={styles.imageContainer}>
  <img
    src={`/games/EgyptianGame/Doors/${DOOR_IMAGES[currentDoor]}`}
    alt={`Door ${currentDoor + 1}`}
    style={styles.doorImage}
  />
      </div>
      <div style={styles.answerContainer}>
        <AnswerChecker
          correctAnswer={DOOR_ANSWERS[currentDoor]}
          onNextGame={(answer: string) => handleNextDoor(answer)}
        />
      </div>
    </div>
  );
}

const styles = {
  verificationContainer: {
    marginTop: "20px",
    padding: "20px",
    border: "1px solid #ccc",
    borderRadius: "8px",
    backgroundColor: "#f9f9f9",
  },
  container: {
    display: "flex",
    flexDirection: "column" as const,
    alignItems: "center",
    padding: "20px",
  },
  imageContainer: {
    margin: "20px 0",
    maxWidth: "800px",
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
  doorImage: {
    width: "50%",
    height: "auto",
    borderRadius: "8px",
    boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
  },
  answerContainer: {
    marginTop: "20px",
    width: "100%",
    maxWidth: "400px",
  },
};
