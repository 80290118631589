// Function to generate an 8-digit verification code from a string of answers
export async function generateVerificationCode(
  answers: string[]
): Promise<string> {
  const concatenatedAnswers = answers.join("|").toLowerCase();

  // Use Web Crypto API to generate hash
  const encoder = new TextEncoder();
  const data = encoder.encode(concatenatedAnswers);

  // Generate SHA-256 hash
  const hashBuffer = await crypto.subtle.digest("SHA-256", data);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  const hashHex = hashArray
    .map((b) => b.toString(16).padStart(2, "0"))
    .join("");

  // Take first 8 characters of hash for verification code
  return hashHex.substring(0, 8);
}
