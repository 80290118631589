
import React, { useState } from 'react';

interface IAnswerCheckerProps {
  onNextGame: (answer: string) => void,
  correctAnswer: string
}

export default function AnswerChecker(props:IAnswerCheckerProps) {
  const {onNextGame, correctAnswer} = props;
  
  const resetState = () => {
    setValue('');
    setShowWrong(false);
    setShowSuccess(false);
    setChecking(false);
  };

  // Wrap onNextGame to reset state before moving to next question
  const handleNextGame = () => {
    resetState();
    onNextGame(value);
  };
  const [value, setValue] = useState('');
  const [checking, setChecking] = useState(false);
  const [showWrong, setShowWrong] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);

  const valueChanged = (event:React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
    setShowWrong(false);
  };

  const arrayBufferToHex = (buffer: ArrayBuffer): string => {
    return Array.from(new Uint8Array(buffer))
      .map(b => b.toString(16).padStart(2, '0'))
      .join('');
  };

  const clickChecked = async () => {
    setChecking(true);
    try {
      const encoder = new TextEncoder();
      const keyData = encoder.encode('esapekey');
      const messageData = encoder.encode(value.toLowerCase());
      
      const key = await crypto.subtle.importKey(
        'raw',
        keyData,
        { name: 'HMAC', hash: 'SHA-256' },
        false,
        ['sign']
      );
      
      const signature = await crypto.subtle.sign(
        'HMAC',
        key,
        messageData
      );

      const hash = arrayBufferToHex(signature);
      console.log(hash);
      
      if (hash === correctAnswer) {
        setShowWrong(false);
        setShowSuccess(true);
      } else {
        setShowWrong(true);
      }
    } catch (error) {
      console.error('Hash calculation failed:', error);
      setShowWrong(true);
    } finally {
      setChecking(false);
    }
  };

  return <>
  { showSuccess ? <div>Yes! <button onClick={handleNextGame}>Next Question</button></div> : 
     checking ? <div>Checking...</div>:
     <div>
       <div>
         The Answer is <input width={20} value={value} onChange={valueChanged}/>
         &nbsp;
         <button onClick={clickChecked}>Check</button>
        </div>
        {
          showWrong && <p style={{color: 'red'}}>Wrong</p>
        }
      </div>
      }
  </>;
}
