import React, { useState } from "react";
import { DoorGame } from "./DoorGame";

export function EgyptGameStart() {
  const [gameStarted, setGameStarted] = useState(false);

  if (gameStarted) {
    return <DoorGame />;
  }

  return (
    <div style={styles.container}>
      <h1>Neferkasokar's Doors</h1>
      <p>Somewhere, lost to the sands of time, lies the ancient, undisturbed tomb of the pharaoh Neferkasokar. 
        Famed for his knowledge and love of puzzles, his tomb was sealed behind a series of enigmatic doors 
        which have until now, remained undiscovered.
        </p>
        <p>
        Now the task of unraveling the secrets of this mysterious labyrinth falls to you, brave explorer.
        Can you successfully navigate your way to the hidden tomb? But more importantly, 
        if you can, will you be able to get back out again?</p>
      <p>You are about to encounter a series of doors. Each door is a puzzle. The answer is a single word or number, no spaces.</p>
      <p>Click the button below to start the game!</p>
      <button onClick={() => setGameStarted(true)} style={styles.button}>
        START
      </button>
    </div>
  );
}

const styles = {
  container: {
    display: "flex",
    flexDirection: "column" as const,
    alignItems: "center",
    padding: "20px",
  },
  button: {
    padding: "10px 20px",
    fontSize: "18px",
    backgroundColor: "#4CAF50",
    color: "white",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
    transition: "background-color 0.3s",
    "&:hover": {
      backgroundColor: "#45a049",
    },
  },
};
