import React from 'react';
import { EgyptGameStart } from './games/EgyptianGame/EgyptGameStart';

const GameSelector = () => {
  return (
    <EgyptGameStart/>
  );
};

export default GameSelector;
